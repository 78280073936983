<template>
  <Toast />
  <Dialog v-model:visible="display" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
    <template #header>
      <h3 v-if="bandera === 0">Nuevo registro</h3>
      <h3 v-else>Actualizar registro</h3>
    </template>
    <form action="">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="firstname">usuario</label>
          <Dropdown
            v-model="userNotification.commonPeopleId"
            :options="peopleList"
            optionLabel="full_name"
            optionValue="id"
            :filter="true"
            placeholder="seleccione"
            :showClear="true"
            @change="handleSelectedPeople"
          />
          <span class="" style="color: darkred">
            {{ errors.commonPeopleId }}
          </span>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="lastname">Correo</label>
          <InputText id="lastname" type="text" v-model="userNotification.email"/>
          <span class="" style="color: darkred">
            {{ errors.email }}
          </span>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="lastname">Telefono</label>
          <InputMask mask="(999) 999-9999" placeholder="(999) 999-9999" v-model="userNotification.phone" :unmask="true"/>
          <span class="" style="color: darkred">
            {{ errors.phone }}
          </span>
        </div>
      </div>
    </form>
      <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeModal" />
      <Button label="Confirmar" icon="pi pi-check" autofocus @click="submit" />
    </template>
  </Dialog>
</template>
<script>
import { onMounted, ref } from 'vue'
import ApiGetCommonPeopleList from '@/apps/pharmasan/accounting/amortizations/services/getCommonPeopleList'
import ApiCreateNotificatios from '@/apps/pharmasan/accounting/amortizations/services/createNotificatios'
import ApiUpdateNotifications from '@/apps/pharmasan/accounting/amortizations/services/updateNotifications'
import { useField, useForm, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { useToast } from 'primevue/usetoast'
import { useDataTable } from '../dataTable/useDataTable'

export default {
  name: 'modalNotifications',
  setup () {
    const { getNotificationsList } = useDataTable()
    const toast = useToast()
    // schema
      const validationSchema = yup.object({
        id: yup.number().nullable(),
        email: yup.string().email().required().label('Email'),
        phone: yup.string().required().label('Telefono'),
        commonPeopleId: yup.number().required().label('Usuario')
      })
    // usefield
      useField('commonPeopleId', null, { initialValue: null })
      useField('email', null, { initialValue: null })
      useField('phone', null, { initialValue: null })
    // use form
    const { errors, handleSubmit, values: userNotification, handleReset } = useForm({ validationSchema })
    // data
      const display = ref(false)
      const peopleList = ref([])
      const bandera = ref(0)
    // hooks
      onMounted(() => {
        commonPeopleList()
        userNotification.id = null
        setTimeout(handleReset, 1000)
      })
    // methods
      const toggle = (value) => {
        bandera.value = value
        display.value = !display.value
      }
      const commonPeopleList = async () => {
        const { result } = await ApiGetCommonPeopleList()
        peopleList.value = result ?? []
      }
      const handleSelectedPeople = ({ value }) => {
        const { email } = peopleList.value.find(x => x.id === value)
        userNotification.email = email
      }
      const defineUserNotification = (values) => {
        userNotification.id = values.id
        userNotification.commonPeopleId = values.commonPeopleId
        userNotification.email = values.email
        userNotification.phone = values.phone
      }
      const submit = handleSubmit((form) => {
        const data = {}
        for (const [key, value] of Object.entries(form)) {
          data[key] = value
        }
        createOrUpdate(data)
      })
      const createOrUpdate = (data) => {
        if (bandera.value === 0) {
          ApiCreateNotificatios(data).then(({ data }) => {
            toast.add({ severity: 'success', summary: 'Nuevo registro', detail: 'Creado con exíto', life: 3000 })
            getNotificationsList()
            closeModal()
          }).catch((err) => console.error(err.message))
        } else {
          ApiUpdateNotifications(data.id, data).then(({ data }) => {
            toast.add({ severity: 'success', summary: 'Actualizacion registro', detail: 'Realizada con exíto', life: 3000 })
            getNotificationsList()
            closeModal()
          }).catch((err) => console.error(err.message))
        }
      }
      const closeModal = () => {
        handleReset()
        display.value = false
      }
    // return
      return {
        toggle,
        display,
        userNotification,
        peopleList,
        handleSelectedPeople,
        useField,
        useForm,
        ErrorMessage,
        errors,
        submit,
        bandera,
        defineUserNotification,
        closeModal
      }
  }
}
</script>
<style scoped>
</style>
